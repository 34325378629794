
import { setCurrentPageBreadcrumbs } from "@/core/helpers/breadcrumb";
import ApiService from "@/core/services/ApiService";
import { computed, defineComponent, onMounted, ref } from "vue";
import CardGroupCompany from "@/components/cards/CardGroupCompany.vue";
import CompaniesGroupCU from "./CompaniesGroupCU.vue";
import Swal from "sweetalert2/dist/sweetalert2.min.js";
import { useStore } from "vuex";
import { hasPermission } from "@/core/helpers/functions";
export default defineComponent({
  name: "ManagementCompaniesGroup",
  components: {
    CardGroupCompany,
    CompaniesGroupCU,
  },
  setup() {
    const store = useStore();
    // eslint-disable-next-line @typescript-eslint/ban-types
    const companiesGroup = ref<Array<object>>([]);
    const typeEvent = ref("");
    const dialogVisible = ref(false);
    const element = ref({});

    const getCompaniesGroup = () => {
      ApiService.get("/api/companiesGroups").then(({ data }) => {
        companiesGroup.value = data;
      });
    };

    const newCompanyGroup = () => {
      typeEvent.value = "create";
      dialogVisible.value = true;
    };

    const editCompanyGroup = (item) => {
      element.value = item;
      typeEvent.value = "update";
      dialogVisible.value = true;
    };

    const deleteCompanyGroup = (id) => {
      Swal.fire({
        text: "Esta seguro que desea eliminar este grupo de empresas",
        icon: "warning",
        showCancelButton: true,
        confirmButtonText: "Yes, delete it!",
        cancelButtonText: "No, keep it",
        customClass: {
          confirmButton: "btn fw-bold btn-light-danger",
          cancelButton: "btn fw-bold btn-light-primary",
        },
      }).then((result) => {
        if (result.isConfirmed) {
          ApiService.delete("/api/companiesGroups/" + id).then(() => {
            const i = companiesGroup.value.findIndex(
              (e: Record<string, any>) => e.id === id
            );
            companiesGroup.value.splice(i, 1);
          });
        }
      });
    };

    const successOperation = (response) => {
      const index = companiesGroup.value.findIndex(
        (el: Record<string, any>) => el.id === response.id
      );
      if (index >= 0) {
        companiesGroup.value[index] = response;
      } else {
        companiesGroup.value.push(response);
      }
      dialogVisible.value = false;
    };

    const resetModal = () => {
      typeEvent.value = "";
      element.value = {};
    };

    const companiesGroupList = computed(() => {
      if (store.getters.searchLocal === "") {
        return companiesGroup.value || [];
      } else {
        return companiesGroup.value.filter((ele: Record<string, any>) =>
          ele.name
            .toLowerCase()
            .includes(store.getters.searchLocal.toLowerCase())
        );
      }
    });
    onMounted(() => {
      setCurrentPageBreadcrumbs(
        "companiesGroupIndex",
        ["companies", "companiesGroup"],
        true
      );
      getCompaniesGroup();
    });
    return {
      newCompanyGroup,
      editCompanyGroup,
      deleteCompanyGroup,
      successOperation,
      resetModal,
      companiesGroupList,
      typeEvent,
      dialogVisible,
      element,
      create_companiesgroup: ref(hasPermission("companies group: create")),
    };
  },
});
