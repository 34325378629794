
import { hasPermission } from "@/core/helpers/functions";
import { defineComponent, ref } from "vue";

export default defineComponent({
  name: "CardGroupCompany",
  components: {},
  props: {
    id: Number,
    logo: {
      type: String,
      required: false
    },
    initials: String,
    name: String,
    item: Object
  },
  setup() {
    return {
      update_companiesgroup: ref(hasPermission("companies group: update")),
      delete_companiesgroup: ref(hasPermission("companies group: delete"))
    };
  }
});
