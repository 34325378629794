
import { defineComponent, ref, onMounted } from "vue";
import { ErrorMessage, Field, Form } from "vee-validate";
import * as Yup from "yup";
import { setCurrentPageBreadcrumbs } from "@/core/helpers/breadcrumb";
import { translate } from "@/core/helpers/functions";
import ApiService from "@/core/services/ApiService";
import { useRoute } from "vue-router";
import { useI18n } from "vue-i18n";

export default defineComponent({
  name: "CompaniesGroupCU",
  components: {
    Field,
    Form,
    ErrorMessage,
  },
  props: {
    typeEvent: String,
    item: Object,
  },
  setup(props, { emit }) {
    const route = useRoute();
    const submitButton = ref<HTMLElement | null>(null);
    const title = ref("");
    const { t } = useI18n({ useScope: "global" });
    const form = ref({
      id: null,
      name: "",
    });
    const inputId = ref({
      loading: false,
      options: [],
      list: [],
      value: [],
      data: [],
    });

    const getCompaniesGroup = () => {
      ApiService.get("/api/companiesGroups").then(({ data }) => {
        inputId.value.list = data;
      });
    };
    const selectCompaniesGroup = (query) => {
      if (query !== "") {
        inputId.value.loading = true;
        setTimeout(() => {
          inputId.value.loading = false;
          inputId.value.options = inputId.value.list.filter(
            (item: Record<string, any>) => {
              return item.name.toLowerCase().indexOf(query.toLowerCase()) > -1;
            }
          );
        }, 200);
      } else {
        inputId.value.options = [];
      }
    };

    onMounted(() => {
      const nameRoute = route.name;
      form.value = {
        id: null,
        name: "",
      };
      if (
        nameRoute === "companiesGroupCreate" ||
        props.typeEvent === "create"
      ) {
        title.value = t("createNewBusinessGroup");
      } else if (
        nameRoute === "companiesGroupUpdate" ||
        props.typeEvent === "update"
      ) {
        title.value = "Actualizar grupo de empresas";
      }

      if (props.item && Object.keys(props.item).length > 0) {
        form.value.id = props.item.id;
        form.value.name = props.item.name;
      }
      if (nameRoute === "companiesGroupUpdate") {
        getCompaniesGroup();
      }

      if (!props.typeEvent) {
        const nav =
          nameRoute == "companiesGroupCreate"
            ? "companiesGroupCreate"
            : "companiesGroupUpdate";
        setCurrentPageBreadcrumbs(nav, ["companies", "companiesGroup"], false);
      }
    });
    const createCompaniesGroup = (params) => {
      ApiService.post("/api/companiesGroups", params)
        .then(({ data }) => {
          emit("success", data);
          submitButton.value?.removeAttribute("data-kt-indicator");
          form.value = {
            id: null,
            name: "",
          };
        })
        .catch(() => {
          submitButton.value?.removeAttribute("data-kt-indicator");
        });
    };
    const updateCompaniesGroup = (params) => {
      ApiService.put("/api/companiesGroups/" + form.value.id, params)
        .then(({ data }) => {
          emit("success", data);
          submitButton.value?.removeAttribute("data-kt-indicator");
        })
        .catch(() => {
          submitButton.value?.removeAttribute("data-kt-indicator");
        });
    };
    const vcompaniesGroup = Yup.object().shape({
      name: Yup.string().required(translate("rname")).label("Name"),
    });
    const onSubmitCG = (values) => {
      if (submitButton.value) {
        submitButton.value.setAttribute("data-kt-indicator", "on");
        if (form.value.id) {
          updateCompaniesGroup(form.value);
        } else {
          createCompaniesGroup(values);
        }
      }
    };

    return {
      route,
      title,
      submitButton,
      vcompaniesGroup,
      form,
      inputId,
      onSubmitCG,
      createCompaniesGroup,
      updateCompaniesGroup,
      getCompaniesGroup,
      selectCompaniesGroup,
    };
  },
});
